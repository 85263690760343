@tailwind base;
@tailwind components;
@tailwind utilities;

.mobile-instance {
  @apply w-fit flex items-center p-2 my-6 justify-center transition-colors duration-200 text-gray-600 rounded-lg cursor-pointer
}

p {
  margin-bottom: 0 !important;
}