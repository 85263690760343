.fc-prev-button, .fc-next-button {
    background-color: #df7423 !important;
    border-color: #df7423 !important;
}

.fc-prev-button:hover, .fc-next-button:hover {
    background-color: #b35e1d !important;
    border-color: #b35e1d !important;
}

.fc-today-button {
    background-color: #df7423 !important;
    border-color: #df7423 !important;
}

.fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button {
    background-color: #df7423 !important;
    border-color: #df7423 !important;
}

.active {
    background-color: #b35e1d !important;
    border-color: #b35e1d !important;
    box-shadow: none !important;
    /*box-shadow: 0 0 0 0.2rem rgba(179, 94, 29, 0.5) !important;*/
}

.fc-daygrid-event-dot {
    border-color: #df7423 !important;
}

.fc-h-event {
    background-color: #df7523dd !important;
    border-color: #df7423 !important;
}

.fc-v-event {
    background-color: #df7523dd !important;
    border-color: #df7423 !important;
}

.fc-timegrid-event .fc-event-main .fc-daygrid-event-dot {
    display: none !important;
}

.fc-toolbar-title {
    text-transform: capitalize !important;
}

.fc-event {
    cursor: pointer;
}

:is(.dark .fc-toolbar-title) {
    color: #fff !important;
}

:is(.dark .fc-daygrid-day-number) {
    color: #fff !important;
}

:is(.dark .fc-event-time) {
    color: #fff !important;
}

:is(.dark .fc-event-title) {
    color: #fff !important;
}